:global {
  .gx-app-sidebar {
    & + .ant-layout {
      .page-footer-wrapper {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 80px;
      }
    }
    &.sidebar-is-expanded {
      & + .ant-layout {
        .page-footer-wrapper {
          left: 280px;
        }
      }
    }
  }
}

.menuButton {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menuItemWrapper {
  &.isActive {
    background: #fafbff !important;
    .menuItem {
      .active {
        color: #243699 !important;
        :global {
          .ant-badge-count {
            color: #243699;
            border-color: #243699;
          }
        }
      }
    }
    &:hover {
      .menuItem {
        .inactive,
        .active {
          color: #243699 !important;
        }
      }
    }
  }
  &:hover {
    .menuItem {
      .inactive {
        color: #92d8ff !important;
      }
    }
  }
}
.menuItem {
  display: flex;
  margin-left: -5px;
  align-items: center;
  height: 100%;
  .active,
  .inactive {
    display: flex;
    > svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      > svg {
        path {
          fill: #92d8ff;
        }
      }
    }
  }
  &:hover {
    .active,
    .inactive {
      > svg {
        path {
          fill: #92d8ff;
        }
      }
    }
  }
  .inactive {
    > svg {
      path {
        fill: white;
      }
    }
  }
  .active {
    > svg {
      path {
        fill: #3047c6 !important;
      }
    }
  }
  &.addMargin {
    margin-left: -13px;
  }
  .menuItemBadge {
    span {
      display: block !important;
    }
  }
  .pendingBadge {
    background: #ff3737;
    border-radius: 50%;
    margin-left: 16px;
    width: 8px;
    height: 8px;
  }
  .myCaseLoad {
    :global {
      .ant-badge-count {
        background: #ff3737;
        box-shadow: unset;
        color: #ffffff !important;
        display: flex;
        align-items: center;
      }
    }
    &.trialsApprovalsBadge {
      display: inline-block !important;
      span {
        display: inline-block !important;
      }
    }
  }
  .all {
    :global {
      .ant-badge-count {
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 9px;
        background: unset;
        box-shadow: unset;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
  }
  &.subMenu {
    margin-left: -48px;
    &.addMargin {
      margin-left: -25px;
    }
    .inactive {
      font-weight: normal;
      color: #d4dfeb;
    }
    .active {
      font-weight: 600;
    }
  }
  :global {
    .material-icons {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 24px;
    }
  }
  .inactive {
    color: white;
    font-weight: 600;
  }
  .active {
    color: #ffffff;
    font-weight: 600;
  }
  .chevron {
    margin-right: -8px;
    color: #92d8ff !important;
    @media (max-width: 768px) {
      margin-right: -15px;
    }
  }
  &.miniSidebar {
    :global {
      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        justify-content: center;
        .gx-mr-3 {
          margin: 0 !important;
          @media (min-width: 768px) {
            margin-left: 20px !important;
          }
          @media (max-width: 768px) {
            margin-right: 20px !important;
          }
        }
      }
    }
  }
  :global {
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-submenu-title {
      margin-left: -16px;
    }
  }
  &:hover {
    .inactive {
      // color: #243699 !important;
    }
  }
  .menuItemWrapper {
    &.isActive {
      background: transparent !important;
    }
  }
}
.rollSheetIcon {
  transform: scale(2.2);
  margin-left: 10px;
}
.header {
  z-index: 2;
  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    &.hasTimerButton {
      height: 112px !important;
      padding-top: 12px;
      align-items: flex-start;
      margin-bottom: 0px;
      &.isRunning {
        height: 200px !important;
        padding-bottom: 70px;
        :global {
          .gx-linebar.gx-mr-3,
          .gx-header-notifications {
            position: relative;
            top: 3px;
          }
          .gx-header-notifications {
            top: 2px;
          }
        }
      }
      &:not(.isRunning) + main {
        padding-top: 50px;
      }
    }
  }
  @media (max-width: 468px) {
    height: 70px !important;
    :global {
      .gx-header-notifications {
        margin: 0 !important;
        .gx-avatar {
          height: 42px !important;
          width: 42px !important;
        }
      }
    }
  }
}
.menuTooltip {
  :global {
    .ant-tooltip-content {
      transform: translate(25px, 0px);
      background: rgba(0, 0, 0, 0.75) !important;
      border-radius: 8px;
    }
    .ant-tooltip-arrow {
      left: -13px;
    }
  }
}
.customScroll {
  height: calc(100vh - 100px) !important;
  > div {
    overflow: auto !important;
    padding-bottom: 15px;
  }
}
.mainNav {
  .chevron,
  .menuItemBadge {
    display: none !important;
  }
  &:global(.ant-layout-sider) {
    width: 80px !important;
    z-index: 2;
  }
  :global {
    .ant-menu-item {
      > a {
        > span {
          &:last-child {
            display: none;
          }
        }
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        > div {
          > div {
            > span {
              visibility: hidden;
            }
          }
        }
      }
      .ant-menu-item {
        height: 10px !important;
        > a {
          > span {
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
  &.isHover {
    .chevron {
      display: flex !important;
    }
    .menuItemBadge {
      display: block !important;
    }
    &:global(.ant-layout-sider) {
      flex: 0 0 auto !important;
      max-width: none !important;
      min-width: 10px !important;
      width: 280px !important;
      overflow: hidden;
    }
    :global {
      .ant-menu-item {
        > a {
          > span {
            &:last-child {
              display: inline-block;
            }
          }
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          > div {
            > div {
              > span {
                visibility: visible;
              }
            }
          }
        }
        .ant-menu-item {
          height: 42px !important;
          > a {
            > span {
              &:last-child {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .menuItem {
      .menuItemWrapper {
        &.isActive {
          background: #fafbff !important;
        }
      }
    }
  }
  &.isHide {
    display: none !important;
  }
}
.timerButton {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
  margin-top: 10px;
  > div {
    > button {
      padding-left: 25px;
      height: 48px;
      width: 180px;
      font-size: 16px;
      > img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.quickbooksBadge {
  :global {
    .ant-badge-count {
      background: #ff3737 !important;
      color: white !important;
      line-height: 20px !important;
    }
  }
}
.dot {
  background: #ff3737;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  &.top {
    position: relative;
    top: -6px;
  }
}
.warningModal {
  :global {
    .ant-modal-confirm-btns {
      > button {
        &:nth-child(1) {
          border-color: #f22013 !important;
          color: #f22013 !important;
        }
      }
    }
    .ant-modal-confirm-body {
      > span:first-child {
        align-items: flex-start;
        padding-top: 32px;
      }
    }
  }
}
.warningModal1 {
  :global {
    .ant-modal-confirm-btns {
      > button {
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
      }
    }
  }
}
