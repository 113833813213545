@mixin modalMobileWrapper() {
  vertical-align: bottom !important;
  margin: 0;
  max-width: 100vw;
  min-width: 100vw;
}
@mixin modalMobileContent() {
  .ant-modal-content {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.16) !important;
    border-radius: 16px 16px 0px 0px !important;
    .ant-modal-header {
      border-radius: 16px 16px 0px 0px !important;
    }
  }
}
@mixin modalMobile() {
  :global {
    .ant-modal {
      @include modalMobileWrapper();
      @include modalMobileContent();
    }
  }
}
@mixin newModal() {
  :global {
    .ant-modal-header {
      border-radius: 16px 16px 0 0;
      .ant-modal-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #1E202B;
      }
    }
    .ant-modal-content {
      border-radius: 16px;
    }
  }
}
@mixin modalFooter() {
  margin: 0 -24px -24px -24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid #E1E1E1;
  padding: 16px 24px;
  button {
    margin: 0 !important;
  }
}
@mixin mergeButton() {
  position: relative;
  &:before,
  &:after {
    content: ' ';
    width: 65px;
    height: 15px;
    display: block;
    border: 1px solid #314eec;
    position: absolute;
    left: -8px;
    opacity: 1;
    border-radius: 0;
    z-index: 100;
    cursor: default;
    pointer-events: none;
  }
  &:before {
    top: -25px;
    border-radius: 0 10px 0 0;
    border-left: 0;
    border-bottom: 0;
  }
  &:after {
    bottom: -25px;
    border-radius: 0 0 10px 0;
    border-top: 0;
    border-left: 0;
    top: auto;
    box-shadow: none;
    animation: none;
  }
}
@mixin moreText($line: 1) {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin selectionColor($background, $color) {
  &::selection {
    background: $background;
    color: $color;
  }
  &::-moz-selection {
    background: $background;
    color: $color;
  }
}

