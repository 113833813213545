@import 'src/assets/scss/mixins';

.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-content {
      max-width: 564px;
      margin: 0 auto;
      border-radius: 16px;
    }
    .ant-modal-close {
      display: none !important;
    }
    .ant-modal-header {
      border-radius: 16px 16px 0 0;
    }
    .ant-modal-title {
      font-weight: 600;
    }
  }
  .modalContent {
    padding: 24px 24px 0 24px;
    max-height: 76vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.footerWrapper {
  margin-top: 36px;
}
.footer {
  padding: 16px 24px 0 24px;
  border-top: 1px solid #d9d9d9;
  margin: 0 -24px;
  display: flex;
  justify-content: flex-end;
  :global {
    .ant-btn:not(:last-child) {
      margin-right: 24px;
    }
  }
}
.names,
.description {
  margin-bottom: 16px;
}
.names {
  display: flex;
  gap: 24px;
  > * {
    flex: 1;
  }
  :global {
    .ant-input.ant-input-disabled {
      background: white;
      color: #acadb3;
    }
  }
}
.description {
  font-weight: 500;
  a {
    text-decoration: underline;
  }
}
.signature {
  display: flex;
  gap: 24px;
  > div {
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &:nth-child(2) {
      min-width: 107px;
      max-width: 107px;
    }
    > label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #545454;
      margin-bottom: 3px;
    }
    > div {
      border-radius: 4px;
      border: 1px solid #d1d1d1;
      padding: 16px 20px;
      color: #1e202b;
      width: 100%;
      font-size: 30px;
      line-height: 36px;
      color: #1e202b;
      flex: 1;
      min-height: 70px;
    }
  }
}

@media screen and (max-width: 576px) {
  .modalWrapper {
    @include modalMobile();
    .modalContent {
      padding: 16px 16px 0 16px;
    }
  }
  .footerWrapper {
    margin-top: 16px;
  }
  .names {
    display: flex;
    flex-direction: column;
  }
}
